export async function selectPurchasedCompany(newActive, itemId) {
  Array.from(document.getElementsByClassName("active")).forEach((element) =>
    element.classList.remove("active")
  );

  newActive.classList.add("active");
  document.getElementById("selection-holder").classList.add("hidden");
  document.getElementById("selection-spinner").classList.remove("hidden");

  try {
    const response = await fetch(`purchased-selection-company-info/${itemId}`);
    const json = await response.json();

    if (json.success) {
      const showButton = document.getElementById("selection-show-selection");
      const updateButton = document.getElementById("selection-update-selection");
      document.getElementById("selection-date").innerText = json.creationTime;
      document.getElementById("selection-title").innerText = json.name;
      document.getElementById("selection-description").innerText = json.description;

      const tags = json.tags.map((tag) => ` <span class="search-tag mt-2">${tag}</span>`).join("");

      document.getElementById("selection-tags").innerHTML = tags;

      document.getElementById("selection-holder").classList.remove("hidden");
      document.getElementById("selection-spinner").classList.add("hidden");

      document.getElementById("query_id").value = json.id;
      document.getElementById("name").value = json.name;
      document.getElementById("description").innerText = json.description;

      document.getElementById("selection-runs-left").innerText = json.runsLeft;
      showButton.setAttribute("href", json.showPath);
      showButton.setAttribute(
        "onclick",
        `Packs.application.setSpinnerInDiv('holder'); window.location.href='${json.showPath}'`
      );
      updateButton.setAttribute("href", json.runPath);
      if (updateButton.form) updateButton.form.setAttribute("action", json.runPath);
      updateButton.setAttribute(
        "onclick",
        `Packs.application.setSpinnerInDiv('holder'); window.location.href='${json.runPath}'`
      );

      if (json.runsLeft > 0) {
        updateButton.classList.remove("disabled");
      } else {
        updateButton.classList.add("disabled");
      }
    }
  } catch (e) {
    console.error(e);
  }
}
