import "select2";

import * as SweetAlert from "sweetalert-messages";
import { getLatestNews } from "../companies";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Test Comment rebuild

require("@rails/ujs").start();
// require("turbolinks").start()
require("channels");

require("chartkick");
require("chart.js");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function setPwOk(regex, id, passwordField) {
  if (passwordField.value.match(regex)) {
    document.getElementById(id).style = "color: green";
  } else {
    document.getElementById(id).style = "color: red";
  }
}

export function verifyPassword(passwordFieldId, submitButtonId, errorId) {
  var passwordField = document.getElementById(passwordFieldId);
  var submitButton = document.getElementById(submitButtonId);
  var error = document.getElementById(errorId);
  setPwOk(/((?=.*[A-Z]).{1,})/, "pw-uppercase", passwordField);
  setPwOk(/((?=.*[a-z]).{1,})/, "pw-lowercase", passwordField);
  setPwOk(/((?=.*[0-9]).{1,})/, "pw-number", passwordField);
  setPwOk(/((?=.*\W).{1,})/, "pw-symbol", passwordField);
  if (passwordField.value.length >= 12) {
    document.getElementById("pw-twelve").style = "color: green";
  } else {
    document.getElementById("pw-twelve").style = "color: red";
  }

  if (
    passwordField.value.match(
      /((?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[0-9]){1,}(?=.*\W){1,}).{12,}/
    )
  ) {
    submitButton.removeAttribute("disabled");
    error.classList.add("hidden");
  } else {
    error.classList.remove("hidden");
    submitButton.setAttribute("disabled", true);
  }
}

export * from "sweetalert-messages";

export async function checkWebhook(userID) {
  const url = "/subscriptions/check-webhook/" + userID;

  const request = {
    method: "get",
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const response = await fetch(url, request);
  const json = await response.json();
  try {
    if (json["subscribed"] === true) {
      clearInterval(window.intervalWebhook);
      SweetAlert.paymentSuccessful();
    }
  } catch (error) {
    console.log("Error happened: " + error);
  }
}

function unblurredFields(companyInfoAbout) {
  return `
  <div class="form-row decor">
    <div class="form-group col-md-8">
      <label for="info">Description</label>
      <textarea class="form-control" id="info" placeholder="${companyInfoAbout}" style="height: 250px"></textarea>
    </div>
    <div class="form-group col-md-4">
      <label for="doelsegment">Partnerships</label>
      <textarea class="form-control" id="Companies_PARTNERSHIPS::listPartnerships" placeholder="" style="height: 250px"></textarea>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="segmen02">Primary Role</label>
      <textarea class="form-control" id="companies_VLPRIMARYROLES::value" rows="2" placeholder="" style="height: 150px"></textarea> 
    </div>
    <div class="form-group col-md-4">
      <label for="segment03">Secondary Roles</label>
      <textarea class="form-control" id="companies_VLSECONDARYROLES::listValues" rows="2" placeholder="" style="height: 150px"></textarea> 
    </div>
  </div>      

  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="doelsegment02">Services focus</label>
      <textarea class="form-control" id="companies_VLSERVICEFOCUS::listValues" rows="2" placeholder="" style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-3">
      <label for="doelsegment03">Software focus</label>
      <textarea class="form-control" id="companies_VLSOFTWAREFOCUS::listValues" rows="2" placeholder="" style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-3">
      <label for="segment">Hardware focus</label>
      <textarea class="form-control" id="companies_VLHARDWAREFOCUS::listValues" rows="2" placeholder=" " style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-3">
      <label for="segment">Solution focus</label>
      <textarea class="form-control" id="companies_VLSOLUTIONFOCUS::listValues" rows="2" placeholder=" " style="height: 150px"></textarea>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="label">Target Horizontal</label>
      <textarea class="form-control" id="companies_VLTARGETHORIZONTAL::listValues" rows="2" placeholder="" style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-4">
      <label for="label02">Target Vertical</label>
      <textarea class="form-control" id="companies_VLTARGETVERTICAL::listValues" rows="2" placeholder="" style="height: 150px"></textarea>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="segmen02">FTE</label>
        <input type="text" class="form-control" id="FTEclass" placeholder=" " disabled>
    </div>
    <div class="form-group col-md-4">
      <label for="segment03">GTM</label>
        <input type="text" class="form-control" id="GTM" placeholder=" " disabled>
    </div>
  </div>                                                                           
  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="segmen02">Certificates</label>
        <textarea class="form-control" id="Companies_CERTIFICATES::listCertificates" placeholder="" rows="2" style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-4">
      <label for="segmen02">Awards</label>
        <textarea class="form-control" id="companies_AWARDS::listAwards" placeholder="" rows="2" style="height: 150px"></textarea>
    </div>
    <div class="form-group col-md-4">
      <label for="segment03">Memberships</label>
        <textarea class="form-control" id="companies_MEMBERSHIPS::listMemberships" placeholder="" rows="2" style="height: 150px"></textarea>
    </div>
  </div>`;
}

export function monthlyYearlySwitch(switchElement) {
  const yearElements = document.getElementsByName("yearly-subscription");
  const monthElements = document.getElementsByName("monthly-subscription");
  if (switchElement.checked) {
    $(yearElements).removeClass("hidden");
    $(monthElements).addClass("hidden");
  } else {
    $(yearElements).addClass("hidden");
    $(monthElements).removeClass("hidden");
  }
}

export function setFsTags(customerId, company) {
  var tags = {
    userID: customerId,
    companyID: company,
  };
  fastspring.builder.push({ tags: tags });
}

function blurredFields(customerId, companyId) {
  return `
<div class="blurred-information modal-information">
<img src="blurred_modal.png">
<div class="upgrade-row">
  <div class="remove-blur">
    <div class="header">
    </div>
    <div class="body">
      Please upgrade your free account to view all information
    </div>
    <div class="footer">
      <a href="/subscriptions/upgrade" class="btn btn-primary">Upgrade</a>
    </div>
  </div>
  <div class="remove-blur">
    <div class="header">\
      Purchase profile
    </div>
    <div class="body d-block">
      Make a one time payment of <span data-fsc-item-path="portal-single-company" data-fsc-item-price></span> excl. VAT to purchase this profile?
    </div>
    <div class="footer">
      <a class="btn btn-primary" data-fsc-item-path-value="portal-single-company" data-fsc-action="Add,Checkout" data-fsc-item-path="portal-single-company" href="javascript:;" id="purchase-company" onmousedown="Packs.application.setFsTags('${customerId}', '${companyId}')">Purchase</a>
    </div>
  </div>
</div>
</div>`;
}

function growButton(buttonId) {
  var purchaseButton = document.getElementById(buttonId);
  purchaseButton.classList.add("grow");
  setTimeout(() => purchaseButton.classList.remove("grow"), 500);
}

export async function fetchCompanyInfo(companyId) {
  var currentCompany = document.getElementById("modal-current-company");
  var modalHeader = document.getElementById("modal-header");
  var modalForm = document.getElementById("modal-form");
  var modalSpinner = document.getElementById("modal-spinner");
  var pdfUrl = document.getElementById("pdf-link");
  var excelUrl = document.getElementById("excel-link");
  var purchaseUrl = document.getElementById("purchase-company");

  if (companyId !== currentCompany.innerText) {
    modalHeader.classList.add("hidden");
    modalForm.classList.add("hidden");
    modalSpinner.classList.remove("hidden");

    currentCompany.innerText = companyId;
    const url = "/company-info/" + companyId;

    const request = {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    };
    const response = await fetch(url, request);
    const json = await response.json();
    getLatestNews(companyId);
    try {
      if (json["hasBlur"]) {
        var pdfLink = document.getElementById("pdf-link");
        var excelLink = document.getElementById("excel-link");
        pdfLink.setAttribute("href", "javascript:;");
        pdfLink.removeAttribute("target");
        excelLink.setAttribute("href", "javascript:;");
        excelLink.removeAttribute("target");
        pdfLink.onclick = () => growButton("purchase-company");
        excelLink.onclick = () => growButton("purchase-company");
      } else {
        var pdfLink = document.getElementById("pdf-link");
        var excelLink = document.getElementById("excel-link");
        pdfLink.setAttribute("href", `/download-pdf/${json["ID"]}`);
        pdfLink.setAttribute("target", "blank");
        excelLink.setAttribute("href", `/download-excel/${json["ID"]}`);
        excelLink.setAttribute("target", "blank");
      }
      if (json["hasBlur"]) {
        document.getElementById("blur-items").innerHTML = blurredFields(
          json["customer"],
          json["ID"]
        );
      } else {
        document.getElementById("blur-items").innerHTML = unblurredFields(
          json["about"]
        );
      }
      Object.keys(json).forEach((value) => {
        const currentField = document.getElementById(value);
        if (currentField && currentField.hasAttribute("placeholder")) {
          currentField.setAttribute(
            "placeholder",
            json[value].replaceAll("\r", ", ")
          );
          if (value.includes("website"))
            document
              .getElementById("company_website_link")
              .setAttribute("href", json[value]);
          if (value.includes("Email"))
            document
              .getElementById("company_email_link")
              .setAttribute("href", `mailto:${json[value]}`);
        } else if (currentField && currentField.hasAttribute("src")) {
          currentField.src =
            json[value] !== ""
              ? `https://partnernavigator.fmserver.nl/RC_Data_FMS/PartnerNavigator/PartnerNavigator/${json[value]}`
              : "/logo_placeholder.png";
        } else if (currentField) {
          currentField.innerText =
            json[value] !== "" ? json[value] : "Not yet defined";
        }
      });
      // document.getElementById("employeeAmount").innerText = json["FTEclass"];
      // document.getElementById("activeSince").innerText = json["startYear"];
      pdfUrl.pathname = pdfUrl.pathname.replace(
        /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/,
        json["ID"]
      );
      excelUrl.pathname = excelUrl.pathname.replace(
        /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/,
        json["ID"]
      );
      modalHeader.classList.remove("hidden");
      modalForm.classList.remove("hidden");
      modalSpinner.classList.add("hidden");
      if (purchaseUrl) {
        purchaseUrl.onmousedown = setFsTags(json["ID"]);
      }
    } catch (error) {
      console.log("Error happened: " + error);
    }
  }
}

export function setBackground(element, color) {
  element.style.background = color;
}

export async function getCompanyCount() {
  const url = "/company-count";

  const request = {
    method: "get",
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const response = await fetch(url, request);
  const json = await response.json();
  try {
    document.getElementById(
      "companycounter"
    ).innerText = `Create your selection out of ${json["companyAmount"]} companies`;
  } catch (error) {
    console.log("Error happened: " + error);
  }
}

export function resetForm(form) {
  $(form).find("input, textarea, select").not('input[type="checkbox"]').val("");
  $(form).find("input").prop('checked', false);
  $(form).find("select").val(null).trigger("change");
  $(".select2-selection__choice").remove();
  Array.from($(".select2-search__field")).forEach((field) => {
    field.setAttribute(
      "placeholder",
      document
        .getElementById(
          field
            .getAttribute("aria-describedby")
            .replace("select2-", "")
            .replace("-container", "")
        )
        .getAttribute("data-placeholder")
    );
    field.setAttribute("style", "width: 100%;");
  });
}

export function setSpinnerInDiv(id) {
  var holder = document.getElementById(id);

  holder.innerHTML = `
  <div id="modal-spinner" class="profile-spinner" style="margin-bottom: 30px;">
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>`;
}

export function fetchNewCompanies(form) {
  var profilesHolder = document.getElementById("profiles-holder");

  profilesHolder.innerHTML = `
  <div id="modal-spinner" class="profile-spinner" style="margin-bottom: 30px;">
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>`;
}

function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

docReady(function () {
  let InfiniteScroll = require("infinite-scroll");

  var profilesHolder = document.querySelector(".profiles-holder");
  if (document.querySelector(".paginate a[rel=next]") !== null) {
    var infScroll = new InfiniteScroll(profilesHolder, {
      path: ".paginate a[rel=next]",
      append: ".item",
      history: false,
      debug: false,
      elementScroll: ".profiles-holder",
      checkLastPage: false,
      scrollThreshold: 10,
    });
    infScroll.on("request", function (body, path, response) {
      var profilesHolder = document.getElementById("profiles-holder");

      profilesHolder.innerHTML += `
      <div id="profile-spinner" class="profile-spinner__small" style="margin-bottom: 30px;">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>`;
    });

    infScroll.on("load", function (body, path, response) {
      var spinner = document.getElementById("profile-spinner");
      spinner.remove();
    });
    infScroll.on("append", function (body, path, response) {
      $(".blur").removeClass("blur");
    });
  }
});

// export async function checkWebhookPurchase(userID, companyId){
//   const url = "/subscriptions/check-webhook/purchase" + userID + '/' + companyId;

//   const request = {
//     method: "get",
//     headers: {
//       Accept: "application/json, text/plain, */*",
//     },
//   };
//   const response = await fetch(url, request);
//   const json = await response.json();
//   try {
//     if (json["purchase"] === true) {
//       SweetAlert.purchaseSuccessful()
//     }
//   } catch (error) {
//     console.log("Error happened: " + error)
//   }
// }

export function cancelUpgrade(upgradeBoxId, blurredElementName) {
  $(blurredElementName).removeClass("blur");
  $(upgradeBoxId).addClass("hidden");
}

export function insertUpgradeRow() {
  const newDiv = document.createElement("div");
  newDiv.classList.add("upgrade-row");
  newDiv.setAttribute("id", "upgrade-row");
  const upgradeRow = `
      <div class="remove-blur"style="width: 80%; height: 360px;">
        <div class="header" style="padding: 10px;height: 30%;line-height: 30px;">
          Ooops its seems like you've hit your monthly selection limit
        </div>
        <div class="body">
          You can wait for the new month when new credits become available or upgrade to a next level subscription to continue now
        </div>
        <div class="footer">
          <a href="javascript:;" class='btn btn-secondary mr-1' onclick="Packs.application.cancelUpgrade(document.getElementById('upgrade-row'), '.blur')">Continue</a>
          <a href="/subscriptions/upgrade" class="btn btn-primary ml-1">Upgrade</a>
        </div>
      </div>
    `;
  Array.from(document.getElementsByClassName("item")).forEach((elem) =>
    elem.classList.add("blur")
  );
  newDiv.innerHTML = upgradeRow;
  document.getElementById("profiles-holder").appendChild(newDiv);
}

export function renderChart(labels, datapoints, colors = []) {
  var oldcanv = $(document.getElementById("chart-1")).find("canvas")[0];
  document.getElementById("chart-1").removeChild(oldcanv);

  var canv = document.createElement("canvas");
  canv.classList.add("chartjs-render-monitor");
  canv.setAttribute("style", "display: block; width: 414px; height: 300px;");
  canv.setAttribute("width", "414");
  canv.setAttribute("height", "300");

  document.getElementById("chart-1").appendChild(canv);

  var ctx = $(document.getElementById("chart-1"))
    .find("canvas")[0]
    .getContext("2d");

  var backgroundColor = colors !== [] ? colors : "#95b2e5";

  var myChart = new Chart(ctx, {
    type: "horizontalBar",
    data: {
      labels: labels,
      datasets: [
        {
          data: datapoints,
          borderColor: backgroundColor,
          lineTension: 0,
          backgroundColor: backgroundColor,
          borderWidth: 1,
          pointRadius: 0.25,
        },
      ],
    },
    options: {
      legend: { display: false },
      title: { text: "", display: false },
      backgroundColor: "#ffffff",
      indexAxis: "y",
      scales: {
        xAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    },
  });
  // $("#chart-1").find("canvas")[0].data("chart", myChart);
}

export function lowerCompanyUpdate(purchasedCompanyId) {
  fetch(`/lower-update-counter/${purchasedCompanyId}`).then((response) => {
    response.json().then((json) => {
      if (json["success"]) {
        const counter = document.getElementById(
          `update-counter-${purchasedCompanyId}`
        );
        var count = parseInt(counter.innerText) - 1;
        counter.innerText = count;
        if (count === 0) {
          counter.parentElement.classList.add("disabled");
          counter.parentElement.removeAttribute("data-target");
          counter.parentElement.removeAttribute("data-toggle");
          counter.parentElement.removeAttribute("onclick");
        }
      }
    });
  });
}

export async function getSelectOptions(selectTag, valueListName) {
  if (!selectTag.querySelector("option")) {
    const response = await fetch(
      `/select-options/saiy324-dt-Fdfsb8/${valueListName}`
    );
    const json = await response.json();
    if (json.success) {
      const options = json.valueListItems.map((option) => {
        return `<option value="${option[1]}}">${option[0]}</option>`;
      });
      selectTag.innerHTML = options.join("");
    }
  }
}

export function fetchCompanies() {
  const companyList = document.getElementById("company-list");
  var companies = "";

  fetch("/companies-widget/xcvgxzicv87b09dsfyhbvxcvho9js8vyg9ny").then(
    (response) => {
      response.json().then((json) => {
        json.forEach((company, index) => {
          if (index >= 7) return;
          companies += `<a class="company" href=${company[2]} target="_blank">
            <div class="img-container"><img src="${company[1]}"></div>
            <div class="company-name">${company[0]}</div>
          </a>`;
        });
        companyList.innerHTML = companies;
      });
    }
  );
}

export function showTab(newActiveId, oldActiveId, parentId) {
  const parent = document.getElementById(parentId);
  const oldActive = parent.querySelector(`#${oldActiveId}`);
  const newActive = parent.querySelector(`#${newActiveId}`);
  const oldActiveTab = parent.querySelector(`#tab-${oldActiveId}`);
  const newActiveTab = parent.querySelector(`#tab-${newActiveId}`);

  oldActiveTab.classList.add("inactive");
  newActiveTab.classList.remove("inactive");
  oldActive.classList.add("hidden");
  newActive.classList.remove("hidden");
}

export * from "../select2-functions";
export * from "../companies";
export * from "../purchased-companies";
