import "sweetalert";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { fetchNewCompanies } from "./packs/application";

export function paymentSuccessful() {
  var redirectText = document.createElement("div");
  redirectText.innerHTML =
    "<div style='color: #8697a8; font-weight: 700; text-align: center; padding: 18px 15px; background-color: #f6f7fb;'>You will be redirected to the selections screen automatically. Or click <a href='/dashboard'>here</a></div>";
  Swal.fire({
    title: "Payment received",
    showConfirmButton: false,
    showCancelButton: false,
    html: redirectText,
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false,
    timerProgressBar: true,
    imageUrl: "/checked.png",
    timer: 5000,
  }).then(() => {
    window.location.href = "/dashboard";
  });
}

export function purchaseSuccessful() {
  var redirectText = document.createElement("div");
  redirectText.innerHTML =
    "<div style='color: #8697a8; font-weight: 700; text-align: center; padding: 18px 15px; background-color: #f6f7fb;'>You will be redirected automatically</div>";
  swal({
    title: "Payment received",
    buttons: false,
    content: redirectText,
    closeOnEsc: false,
    closeOnClickOutside: false,
    icon: "/checked.png",
    timer: 5000,
  }).then(() => {
    window.location.href = "/aangekochte-profielen";
  });
}

export function upgradeSuccessful(newProduct) {
  var redirectLink = document.createElement("span");
  redirectLink.innerHTML =
    "Click <a href='/dashboard'>here</a> to start using your new subscription!";
  swal({
    title: "Your upgrade has been validated",
    text: "Your new subscription is: " + newProduct,
    content: redirectLink,
    buttons: false,
    closeOnEsc: false,
    closeOnClickOutside: false,
    icon: "/checked.png",
  });
}

export function nthCompanyReached(n) {
  swal({
    text: `You have reached the ${n} company in your list`,
    showConfirmButton: true,
    timer: 5000,
    icon: "info",
  });
}

export function deactivateSuccessful() {
  swal({
    html: "<h4>Your subscription has been canceled </h4><br/><div>We are sad to see you go!</div>",
    showConfirmButton: true,
    timer: 5000,
    icon: "success",
  });
}

export function paymentFailed() {
  swal({
    title: "Your payment could not be validated",
    text: "Please check your payment details. Until then you have been moved to the free tier",
    icon: "error",
  });
}

export function uploadNotCompleted(form) {
  swal({
    title: "Your file(s) could not be uploaded",
    text: "Some of the file(s) you chose could not be uploaded, because the maximum storage capacity has been reached",
    buttons: {
      cancel: "Don't create profile",
      create: {
        text: "Create profile without file(s)",
        value: "create",
      },
    },
    icon: "warning",
  }).then((value) => {
    if (value === "create") {
      resubmitForm(form);
    }
  });
}

function resubmitForm(form) {
  if (
    !document.getElementsByClassName("direct-upload direct-upload--complete")
      .length > 0
  ) {
    form.removeChild(document.getElementById("talent_profile_picture"));
    form.removeChild(document.getElementById("talent_photos"));
  }
  form.submit();
}

export function awaitingPayment() {
  Swal.fire({
    title: "Please wait, your payment is being validated",
    text: "FastSpring is validating your payment details, please have patience. You will be redirected automatically",
    icon: "info",
    showConfirmButton: false,
    showCancelButton: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
    allowEnterKey: false,
  });
}

export function awaitingPaymentPurchase() {
  var redirectText = document.createElement("div");
  redirectText.innerHTML =
    "<div style='color: #8697a8; font-weight: 700; text-align: center; padding: 18px 15px; background-color: #f6f7fb;'>You will be redirected automatically. Or click <a href='/dashboard'>here</a> to continue browsing</div>";
  swal({
    title: "Please wait, your payment is being validated",
    content: redirectText,
    icon: "info",
    buttons: false,
    closeOnEsc: false,
    closeOnClickOutside: false,
  });
}

export function pdfGenerationFailed() {
  swal({
    title: "Generating PDF failed",
    text: "Something went wrong whilst generating the PDF. We're sorry for the inconvience",
    icon: "error",
  });
}

export function confirmMessage(
  form,
  currentQueries,
  currentProfiles,
  newProfiles,
  recentProfiles,
  totalFound
) {
  const extraInfo =
    parseInt(totalFound) > parseInt(currentProfiles)
      ? `<div class="row w-100 d-block mt-1" style="font-size: 14px;text-align: left;padding-left: 15px;position: relative;">
    <div class="info-button" style="right: unset; top: 5px;">
      <img src="/information.svg">
    </div>
    <span style="margin-left: 40px">Your search yielded ${totalFound} profiles. In your current subscription you have ${parseInt(
          currentProfiles
        )} credits available.</span><br/><span style="margin-left: 40px">You have 5 options:</span>
        <ol style="margin-left: 50px;">
          <li>Download ${parseInt(
            currentProfiles
          )} of the ${totalFound} profiles found now by selecting Confirm</li>
          <li>Next month with new credits you can re-run your query from the history under myprofile</li>
          <li>Select Cancel and refine your query</li>
          <li>Upgrade your account for more credits immediatly</li>
          <li>Contact us at <a href="info@partnernavigator.nl">info@partnernavigator.nl</a></li>
        </ol>
  </div>`
      : "";

  Swal.fire({
    title: "Are you sure you want to submit this selection?",
    html: `
    <div class="row w-100 d-block" style="font-size: 14px; text-align: left; padding-left: 15px;">
      Your monthly credits will be updated as shown below
    </div>
    ${extraInfo}
    <div class="row w-100 d-flex mt-5" style="justify-content: space-evenly;">
      <div class="col-12 col-md-3 upgrade-box">
        <div class="upgrade-box-title__1">
          Your current credits
        </div>
        <div class="upgrade-box-content pt-5 pb-3 pl-1 pr-1">
          <ul class="p-0" style="list-style: none;">
            <li class="mt-2">Profiles: ${currentProfiles}</li>
            <li class="mt-2">Selections: ${currentQueries}</li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-3 upgrade-box">
        <div class="upgrade-box-title__2">
          Change in credits
        </div>
        <div class="upgrade-box-content pt-5 pb-3 pl-1 pr-1">
          <ul class="p-0 mb-1" style="list-style: none;">
            <li class="mt-2">${
              parseInt(newProfiles) + parseInt(recentProfiles)
            } profiles in selection of which:</li>
            <li class="mt-2">${newProfiles} new profiles</li>
            <li class="mt-2">${recentProfiles} recent profiles<sup>*</sup></li>
            <li class="mt-2">1 query</li>
            <li class="mt-2"><small>* Of the ${
              parseInt(newProfiles) + parseInt(recentProfiles)
            } profiles in this selection you already confirmed ${recentProfiles} before. These will not be deducted from your credits</small></li>            
          </ul>          
        </div>
      </div>
      <div class="col-12 col-md-3 upgrade-box">
        <div class="upgrade-box-title__3">
          Your credits after selection
        </div>
        <div class="upgrade-box-content pt-5 pb-3 pl-1 pr-1">
          <ul class="p-0" style="list-style: none;">
            <li class="mt-2">Profiles: ${
              parseInt(currentProfiles) - parseInt(newProfiles)
            }</li>
            <li class="mt-2">Selections: ${
              currentQueries -
              parseInt(document.getElementById("reload").innerText)
            }</li>
          </ul>
        </div>
      </div>
    </div>`,
    icon: "",
    confirmButtonText: "Submit",
    showConfirmButton: true,
    reverseButtons: true,
    showCancelButton: true,
    width: 800,
  }).then((response) => {
    if (response.isConfirmed) {
      fetchNewCompanies(form);
      form.submit();
    }
  });
}

export function confirmUpgradeMessage(
  path,
  chosenUpgrade,
  price,
  upDown,
  hideId = "",
  second = ""
) {
  swal({
    title: `Are you sure you want to ${upDown} to ${chosenUpgrade}?`,
    text: `Your subscription will be ${upDown}d to ${chosenUpgrade}, which means your new price will be: ${price}.`,
    icon: "info",
    buttons: {
      cancel: "Cancel",
      create: {
        text: "Confirm",
        value: "confirm",
      },
    },
  }).then((value) => {
    if (value === "confirm") {
      window.location.href = path;
      const hideElement = document.getElementById(hideId);
      hideElement.innerHTML = `<div id="modal-spinner" class="text-center" style="margin-bottom: 30px;">
      <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>`;
    }
  });
}
