import "select2";
import "select2/dist/css/select2.css";

import $ from "jquery";

export function initiliazeSelect2Tag(element, valueListName) {
  $(element).select2({
    tags: valueListName === "partners",
    closeOnSelect: false,
    ajax: {
      url: `/select-options/saiy324-dt-Fdfsb8/${valueListName}`,
      processResults: function (data) {
        return {
          results: data.valueListItems.map((item) => {
            return { id: item[1], text: item[0] };
          }),
          pagination: false,
        };
      },
      cache: true,
    },
  });
}
