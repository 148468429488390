import { fetchNewCompanies, renderChart } from "./packs/application.js";
import Swal from "sweetalert2";
let InfiniteScroll = require("infinite-scroll");

export function showUpdatePopup(queryId, queryName) {
  Swal.fire({
    title: "Thank you for re-running this query!",
    html: `
      <div class="row w-100 d-block" style="font-size: 14px; text-align: left; padding-left: 15px;">
        You have confirmed and purchased this query on <date> . Witin 12 months after purchasing you may re-run this query for 4 times and you will receive updated profiles for companies you have received in this query at no additional costs.<br/><br/>
      
        If this query now finds new profiles, you may choose to confirm and purchase these additional profiles within your current subscription.<br/><br/>
        
        If you choose to change the query, this will be seen as a new query, and it will be deducted from the available query-credits in your subscription.
      </div>`,
    icon: "",
    confirmButtonText: "Confirm",
    showConfirmButton: true,
    reverseButtons: true,
    showCancelButton: true,
    width: 800,
  }).then((response) => {
    if (response.isConfirmed) {
      window.location.href = `/update-query/${queryId}`;
    }
  });
}

export function performPreFind(searchForm) {
  fetchNewCompanies(searchForm);
  const spinner = document.getElementById("modal-spinner");
  const confirmButton = document.getElementById("selection-confirm");
  confirmButton.classList.add("disabled");
  const formData = new FormData(searchForm);
  const asString = new URLSearchParams(formData).toString();
  var url = "/companies/prefind?format=json&" + asString;

  var request = {
    method: "GET",
    accept: "application/json",
  };
  fetch(url, request)
    .then((response) => response.json())
    .then((result) => {
      var newBody = "";
      const profilesHolder = document.getElementById("profiles-holder");
      const excelDownload = document.getElementById("excel-download");
      const companyResultAmount = document.getElementById(
        "company-result-amount"
      );
      const companyResultText = document.getElementById("result-text");
      companyResultText.innerText = "companies";
      const companyResultSample = document.getElementById("sample");
      companyResultSample.classList.add("hidden");

      result["companies"].map((company, index) => {
        newBody += `<div class="item" style="${
          index == 0 ? "margin-top: 0px" : ""
        }">
          <img src="${
            company["companyLogoPath"] !== ""
              ? `https://partnernavigator.fmserver.nl/RC_Data_FMS/PartnerNavigator/PartnerNavigator/${company["companyLogoPath"]}`
              : "/logo_placeholder.png"
          }" data-toggle='tooltip' data-placement='left' title="Please confirm your selection by clicking the button 'Confirm selection' to see the full profile of ${
          company["companyName"]
        }" />
          <div class="text" data-toggle='tooltip' data-placement='left' title="Please confirm your selection by clicking the button 'Confirm selection' to see the full profile of ${
            company["companyName"]
          }">
            <h3><a href="#">${company["companyName"]}</a></h3>
            <p>${[
              company["officeType"],
              company["companies_VLPRIMARYROLES::value"],
              company["startYear"],
              company["FTEclass"],
            ]
              .filter((value) => value !== "")
              .join(" - ")}</p>
          </div>
          <div class="btn btn-modal" data-toggle='tooltip' data-placement='left' title="Please confirm your selection by clicking the button 'Confirm selection' to see the full profile of ${
            company["companyName"]
          }">
            <img src="${"/icon-eye.png"}" width='35' height='34'/>
          </div>              
        </div>`;
      });
      profilesHolder.innerHTML = newBody;
      if (excelDownload) excelDownload.setAttribute("href", "#");
      const newCompanyCounter = document.getElementById("profiles-new");
      const oldCompanyCounter = document.getElementById("profiles-old");
      const totalCompanyCounter = document.getElementById("profiles-total");

      if (result["companies"][0]) {
        const scriptResult = JSON.parse(
          result["companies"][0]["post_request_script_result"]
        );

        newCompanyCounter.innerText = scriptResult["newCompanyCount"];
        oldCompanyCounter.innerText = scriptResult["oldCompanyCount"];
        totalCompanyCounter.innerText = scriptResult["totalFound"];
        companyResultAmount.innerText = `${result["foundCount"]} out of ${scriptResult["totalFound"]}`;

        var locationdata = scriptResult["locations"];
        var graphdata = scriptResult["graph"];

        confirmButton.classList.remove("disabled");
        map_initialize(locationdata);
        renderChart(
          Object.keys(graphdata),
          Object.keys(graphdata).map((key) => graphdata[key]),
          scriptResult["colors"].split(",")
        );
      } else {
        newCompanyCounter.innerText = 0;
        oldCompanyCounter.innerText = 0;
        totalCompanyCounter.innerText = 0;
        companyResultAmount.innerText = `${0} out of ${0}`;

        var locationdata = {};
        var graphdata = {};

        confirmButton.classList.remove("disabled");
        map_initialize(null);
        renderChart(
          Object.keys(graphdata),
          Object.keys(graphdata).map((key) => graphdata[key]),
          []
        );
      }

      if (result["updateQuery"] === true) {
        const confirmButton = document.getElementById("selection-confirm");
        confirmButton.setAttribute(
          "onclick",
          `Packs.application.showUpdatePopup('${result["queryId"]}', '${result["queryName"]}');`
        );
        confirmButton.classList.remove("disabled");
      } else {
        const confirmButton = document.getElementById("selection-confirm");
        if (result["noCredits"]) {
          confirmButton.setAttribute(
            "onclick",
            `Packs.application.insertUpgradeRow();`
          );
        } else {
          confirmButton.setAttribute(
            "onclick",
            `Packs.application.confirmMessage(document.getElementById(
              "searchForm"
            ), '${document.getElementById("selections-left").innerText}', '${
              document.getElementById("profiles-left").innerText
            }', '${document.getElementById("profiles-new").innerText}', '${
              document.getElementById("profiles-old").innerText
            }', '${document.getElementById("profiles-total").innerText}')`
          );
        }
        confirmButton.classList.remove("disabled");
      }
      $('[data-toggle="tooltip"]').tooltip();

      if (result["pages"] > 1) {
        let setPageIndex = false;
        if (window.infScroll) window.infScroll.destroy();
        if (document.getElementById("paginator")) {
          document
            .getElementById("next-link")
            .setAttribute("href", result.nextPageLink);
          setPageIndex = true;
        } else {
          profilesHolder.parentElement.innerHTML += `<div class="paginate hidden" id="paginator">
                <a href="${result["nextPageLink"]}" class="page" rel="next" id="next-link">Next</a>
                </div>`;
        }

        window.infScroll = new InfiniteScroll(
          document.getElementById("profiles-holder"),
          {
            path: function () {
              return document.getElementById("next-link").href;
            },
            append: ".item",
            history: false,
            debug: true,
            elementScroll: ".profiles-holder",
            checkLastPage: false,
            loadOnScroll: false,
            scrollThreshold: 800,
          }
        );

        if (setPageIndex) {
          setPageIndex = false;
          window.infScroll.pageIndex = 1;
          window.infScroll.loadCount = 0;
        }

        console.log(
          "=======================REQUEST START====================="
        );
        console.log(document.getElementById("next-link").href);
        console.log(result.nextPageLink);
        console.log(window.infScroll.getPath());
        console.log(window.infScroll.pageIndex);
        console.log(
          "=======================REQUEST  DONE====================="
        );

        $('[data-toggle="tooltip"]').tooltip();
        window.infScroll.on("request", function (body, path, response) {
          $('[data-toggle="tooltip"]').tooltip();
          if ($('*[role="tooltip"]')) $('*[role="tooltip"]').remove();
        });
        window.infScroll.on("scrollThreshold", function (body, path, response) {
          if (!document.getElementById("profile-spinner")) {
            var profilesHolder = document.getElementById("profiles-holder");
            profilesHolder.innerHTML += `
              <div id="profile-spinner" class="profile-spinner__small" style="margin-bottom: 30px;">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>`;
          }
          window.infScroll.loadNextPage();
        });

        window.infScroll.on("load", function (body, path, response) {
          var spinner = document.getElementById("profile-spinner");
          if (spinner) spinner.remove();
          var paginatorLink = document.getElementById("next-link");
          var currentPage = parseInt(
            paginatorLink.href.match(/page=\d+/)[0].match(/\d+/)[0]
          );
          var nextPage = currentPage + 1;
          paginatorLink.href = paginatorLink.href.replaceAll(
            `page=${currentPage}`,
            `page=${nextPage}`
          );
        });
        window.infScroll.on("append", function (body, path, response) {
          $('[data-toggle="tooltip"]').tooltip();
          $(".blur").removeClass("blur");
        });
      }
    })
    .catch((error) => {
      console.log(error);
      spinner.classList.add("hidden");
    });
}

export async function getLatestNews(companyId) {
  const spinner = document.getElementById("latest-news-spinner");
  const latestNews = document.getElementById("latest-news-company");
  spinner.classList.remove("hidden");
  latestNews.classList.add("hidden");
  latestNews.innerHTML = "";

  const response = await fetch(`/latest-news/${companyId}`);
  const json = await response.json();

  if (json.success) {
    const newNewsItems = json.news.map((newsItem, index) => {
      if (newsItem.news.includes("http")) {
        const links = Array.from(
          newsItem.news.matchAll(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g
          )
        );
        const otherLinks = links
          .map((v, i) => {
            if (i < links.length - 1) return v[0];
          })
          .filter((v) => v);
        otherLinks.forEach(
          (link) =>
            (newsItem.news = newsItem.news.replace(
              link,
              `<a href="${link}">${link}</a>`
            ))
        );
        const readMoreLink = links[links.length - 1][0];
        return `<div class="news">
          <div>
          <span class="content">
            ${newsItem.news.slice(0, newsItem.news.lastIndexOf("http"))}
            <a href="${readMoreLink}" target="_blank">Read more</a>
          </span>
          <span class="date">${newsItem.date}</span>
          </div>
        </div>`;
      }
      return `<div class="news">
        <div>
        <span class="content">${newsItem.news}</span>
        <span class="date">${newsItem.date}</span>
        </div>
      </div>`;
    });
    latestNews.innerHTML = newNewsItems.join("");
  }
  spinner.classList.add("hidden");
  latestNews.classList.remove("hidden");
}

export async function getCombinedNews(companyIds) {
  const spinner = document.getElementById("combined-news-spinner");
  const combinedNews = document.getElementById("news-box");
  spinner.classList.remove("hidden");
  combinedNews.classList.add("hidden");
  combinedNews.innerHTML = "";

  const response = await fetch(`/combined-news/${companyIds}`);
  const json = await response.json();

  if (json.success) {
    const newNewsItems = json.news.map((newsItem, index) => {
      if (newsItem.news.includes("http")) {
        const links = Array.from(
          newsItem.news.matchAll(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g
          )
        );
        const otherLinks = links
          .map((v, i) => {
            if (i < links.length - 1) return v[0];
          })
          .filter((v) => v);
        otherLinks.forEach(
          (link) =>
            (newsItem.news = newsItem.news.replace(
              link,
              `<a href="${link}" target="_blank">${link}</a>`
            ))
        );
        const readMoreLink = links[links.length - 1][0];

        return `<div class="news">
        <img class="logo" src="${
          newsItem.companyLogoPath && newsItem.companyLogoPath !== ""
            ? `https://partnernavigator.fmserver.nl/RC_Data_FMS/PartnerNavigator/PartnerNavigator/${newsItem.companyLogoPath}`
            : "/logo_placeholder.png"
        }"/>
          <div style="width: calc(100% - 55px)">
            <h3 class="company-name" title="${newsItem.companyName}">${
          newsItem.companyName
        }</h3>
            <span class="content">
              ${newsItem.news.slice(0, newsItem.news.lastIndexOf("http"))}
              <a href="${readMoreLink}" target="_blank">Read more</a>
            </span>
            <span class="date">${newsItem.date}</span>
          </div>
        </div>`;
      }
      return `<div class="news">
        <img class="logo" src="${
          newsItem.companyLogoPath && newsItem.companyLogoPath !== ""
            ? `https://partnernavigator.fmserver.nl/RC_Data_FMS/PartnerNavigator/PartnerNavigator/${newsItem.companyLogoPath}`
            : "/logo_placeholder.png"
        }"/>
        <div style="width: calc(100% - 55px)">
          <h3 class="company-name" title="${newsItem.companyName}">${
        newsItem.companyName
      }</h3>
          <span class="content">${newsItem.news}</span>
          <span class="date">${newsItem.date}</span>
        </div>
      </div>`;
    });
    combinedNews.innerHTML = newNewsItems.join("");
  }
  spinner.classList.add("hidden");
  combinedNews.classList.remove("hidden");
}
